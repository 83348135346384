<template>
    <split-pane :min-percent="30" :default-percent="50" split="vertical">
        <template slot="paneL">
            <Viewer
                v-if="!!width"
                :file="file"
                :showAllPages="true"
                :page="page"
                :bboxes="bboxes"
                :activeBboxIndex="activeBboxIndex"
                :scale="scale"
                :colorScheme="colorScheme"
                :width="width"
                :onLoadSuccess="viewerLoadSuccess"
                :onPageChange="viewerPageChange"
                :onBboxClick="viewerBboxClick"
                :onBboxesParsed="viewerBboxesParsed"
            />
            <div class="page-top" ref="viewer">
                <div class="page-zoom">
                    <button
                        @click="zoomOut"
                        :disabled="scale === 0.25"
                        class="zoomButton"
                        tabindex="2"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="currentColor"
                            :aria-label="$t('results.zoomOut')"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"
                            />
                        </svg>
                    </button>
                    <button @click="zoomIn" :disabled="scale === 2" class="zoomButton" tabindex="2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="currentColor"
                            :aria-label="$t('results.zoomIn')"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="page-bottom">
                <transition name="fade">
                    <div class="page-status" v-if="showPage">
                        {{ page }} {{ $t('results.of') }} {{ pages }}
                    </div>
                </transition>
            </div>
        </template>
        <template slot="paneR">
            <div class="results">
                <h1 class="hide-visually">{{ $t('results.results') }} ({{ score }}%)</h1>
                <div class="results-container">
                    <div
                        aria-hidden="true"
                        class="score"
                        :class="{
                            'score-good': `${score}` > 75,
                            'score-average': `${score}` <= 75 && `${score}` > 25,
                            'score-bad': `${score}` <= 25,
                        }"
                    >
                        <svg viewBox="0 0 36 36" class="score-container">
                            <path
                                class="score-bg"
                                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                class="score-circle"
                                :stroke-dasharray="`${score}` + ',' + '100'"
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155
                        15.9155 0 0 1 0 -31.831"
                            />
                        </svg>
                        <div class="score-percentage">{{ score }}<span>%</span></div>
                    </div>

                    <div class="score-message" role="alert">
                        {{ scoreMessage }}
                    </div>

                    <ul v-if="criticalRules.length > 0">
                        <CriticalRule
                            v-for="rule in criticalRules"
                            v-bind:key="rule.id"
                            :rule="rule"
                        />
                    </ul>

                    <div class="checkbox-container" v-if="criticalRules.length > 0">
                        <input
                            id="show-all-errors-checkbox"
                            type="checkbox"
                            class="show-all-errors-checkbox"
                            v-model="showAllErrors"
                        />
                        <label class="checkbox-text" for="show-all-errors-checkbox">
                            {{ $t('results.showAllAccessibilityErrors') }}
                        </label>
                    </div>

                    <div
                        class="show-warnings-checkbox-container"
                        v-if="criticalRules.length === 0 || showAllErrors"
                    >
                        <div class="warning-explanation">
                            <div class="warning-explanation__warning">
                                <WarningIcon />
                                <label>{{ $t('results.warning') }}</label>
                            </div>
                            <div class="warning-explanation__error">
                                <ErrorIcon />
                                <label>{{ $t('results.error') }}</label>
                            </div>
                        </div>
                        <div class="show-warnings-wrapper">
                            <input
                                id="show-warnings-checkbox"
                                type="checkbox"
                                class="show-warnings-checkbox"
                                v-model="showWarnings"
                            />
                            <label class="checkbox-text" for="show-warnings-checkbox">
                                {{ $t('results.showWarnings') }}
                            </label>
                        </div>
                    </div>

                    <ul v-if="criticalRules.length === 0 || showAllErrors">
                        <Category
                            v-for="(category, index) in categories"
                            v-bind:key="`${index} - ${updatedIndex}`"
                            :guid="index"
                            :name="category"
                            :rules="rules[index]"
                            :activeBboxIndex="activeBboxIndex"
                            @setBbox="setBbox"
                        />
                    </ul>
                    <div class="buttons">
                        <router-link to="/" class="button large tertiary">
                            <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 16 16"
                                version="1.1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <g
                                        id="arrow_back_black_24dp"
                                        transform="translate(-4.000000, -4.000000)"
                                        fill="currentColor"
                                        fill-rule="nonzero"
                                    >
                                        <polygon
                                            id="Path"
                                            points="20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13"
                                        ></polygon>
                                    </g>
                                </g>
                            </svg>
                            {{ $t('results.backToStart') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
    </split-pane>
</template>

<script>
import SplitPane from 'vue-splitpane'
import CriticalRule from '@/components/CriticalRule.vue'
import Category from '@/components/Category.vue'
import { ReactInVue } from 'vuera'
import PdfViewer from 'verapdf-js-viewer'
import * as scoring from '@/scoring.json'
import ErrorIcon from '@/components/ErrorIcon.vue'
import WarningIcon from '@/components/WarningIcon.vue'
import errorMeta from '@/errorMeta.json'
import errorMessagesNL from '@/errorMessages_nl.json'
import errorMessagesEN from '@/errorMessages_en.json'
import errorMessagesDE from '@/errorMessages_de.json'
import errorMessagesTechnical from '@/errorMessagesTechnical.json'

export default {
    name: 'Results',
    components: {
        SplitPane,
        CriticalRule,
        Category,
        ErrorIcon,
        WarningIcon,
        Viewer: ReactInVue(PdfViewer),
    },
    props: {
        file: File,
        ruleSummaries: Array,
    },
    data() {
        return {
            errorMessages: null,
            error: null,
            width: null,
            page: 1,
            showPage: false,
            pageTimer: '',
            pages: 1,
            scale: 1,
            categoryNames: {},
            activeBboxIndex: null,
            colorScheme: {
                border: '#949494',
                background: 'transparent',
                borderHovered: '#000000',
                backgroundHovered: 'transparent',
                borderSelected: '#0771f2',
                backgroundSelected: 'transparent',
                borderRelated: '#FF6200',
                backgroundRelated: 'transparent',
            },
            criticalRules: [],
            rules: [],
            updatedIndex: 0,
            bboxes: undefined,
            bboxPages: null,
            score: 0,
            scoreMessage: '',
            showWarnings: false,
            showAllErrors: false,
        }
    },
    computed: {
        categories: function() {
            return Object.fromEntries(
                Object.entries(this.categoryNames).filter(([key]) => this.rules[key])
            )
        },
    },
    watch: {
        '$i18n.locale': {
            handler: function() {
                this.fetchTranslations()
                this.init()
                this.updateCategoryNames()
            },
        },
        errorMessages: {
            handler: function() {
                this.init()
            },
        },
        rules: {
            handler: function() {
                this.updatedIndex += 1
            },
            deep: true,
        },
        showWarnings: {
            handler: function() {
                this.init()
            },
        },
    },
    mounted: function() {
        this.init()
    },
    created() {
        this.fetchTranslations()
        this.updateCategoryNames()
    },
    destroyed() {
        clearTimeout(this.pageTimer)
    },
    methods: {
        init() {
            const bboxes = []
            const score = {
                critical: 0,
                error: 0,
                serious: 0,
                warning: 0,
            }
            this.ruleSummaries.forEach(rule => {
                const { specification, clause, testNumber } = rule

                const errorClause = this.errorMessages?.find(
                    item => item.specification === specification && item.clause === clause
                )

                if (!errorClause) {
                    console.log(`No error clause found for ${specification} ${clause}`)
                    return
                }

                const errorInformation = errorClause.testNumbers?.find(
                    item => item.number.toString() === testNumber.toString()
                )

                if (!errorInformation) {
                    console.log(
                        `No error information found for ${specification} ${clause} ${testNumber}`
                    )
                    return
                }

                const { status } = errorInformation

                // Don't show these rules
                if (status === 'ignore') {
                    return
                }
                // Don't show rules without a message
                if (!errorInformation.message?.summary) {
                    console.log(`No message found for ${specification} ${clause} ${testNumber}`)
                    return
                }

                if (status) {
                    score[status]++
                }

                rule.checks.forEach(check => {
                    const { location, context } = check

                    let groupId = null
                    if (check.errorArguments.length > 2) {
                        // Check is part of a group
                        groupId = `${clause}-${testNumber}-${check.errorArguments[2]}`
                    }

                    bboxes.push({
                        location: location || context,
                        groupId,
                    })
                })
            })

            // Don't show bboxes if only critical errors are shown
            if (score.critical === 0) {
                // TODO: fix app crashing when no bboxes are passed to viewer
            }
            this.bboxes = bboxes

            if (score.critical > 0) {
                this.scoreMessage = this.$t('results.criticalScoreMessage')
            } else if (score.error > 0 || score.serious > 0) {
                this.scoreMessage = this.$t('results.seriousScoreMessage')
            } else if (score.warning > 0) {
                this.scoreMessage = this.$t('results.warningScoreMessage')
            } else {
                this.scoreMessage = this.$t('results.passedScoreMessage')
            }

            this.score = this.calculateScore(score.critical, score.serious, score.error)

            this.width = Math.min(800, this.$refs.viewer.clientWidth - 112)

            this.$matomo.trackGoal(config.VUE_APP_ENV_MATOMO_UPLOAD_GOAL_ID)
        },
        async fetchTranslations() {
            console.log(`Config VUE_APP_CMS_BASE_URL: ${config.VUE_APP_CMS_BASE_URL}`)
            if (config.VUE_APP_CMS_BASE_URL === undefined) {
                console.log('Using local error messages')
                const errorMessages =
                    this.$i18n.locale === 'en'
                        ? errorMessagesEN
                        : this.$i18n.locale === 'de'
                        ? errorMessagesDE
                        : errorMessagesNL

                const combinedMessages = Object.entries(errorMessages).reduce(
                    (accFinal, [specification, clauses]) => {
                        const messagesPerSpecification = Object.entries(clauses)?.map(
                            ([clause, testNumbers]) => ({
                                clause: clause,
                                specification: specification,
                                testNumbers: Object.entries(testNumbers).map(
                                    ([testNumber, message]) => ({
                                        number: testNumber,
                                        status:
                                            errorMeta?.[specification]?.[clause]?.[testNumber]
                                                ?.STATUS,
                                        category:
                                            errorMeta?.[specification]?.[clause]?.[testNumber]
                                                ?.CATEGORY,
                                        message: {
                                            summary: message.SUMMARY,
                                            description: message.DESCRIPTION,
                                        },
                                        technical: {
                                            summary:
                                                errorMessagesTechnical?.[specification]?.[clause]?.[
                                                    testNumber
                                                ]?.SUMMARY,
                                            description:
                                                errorMessagesTechnical?.[specification]?.[clause]?.[
                                                    testNumber
                                                ]?.TECHNICAL_DESCRIPTION,
                                        },
                                    })
                                ),
                            })
                        )
                        return messagesPerSpecification
                            ? [...accFinal, ...messagesPerSpecification]
                            : accFinal
                    },
                    []
                )

                this.errorMessages = combinedMessages
                return
            }

            try {
                console.log('Fetching error messages')
                const wcagRulesResponse = await fetch(
                    `${config.VUE_APP_CMS_BASE_URL}/api/wcag?limit=0&locale=${this.$i18n.locale ||
                        'nl'}`
                )
                const isoRulesResponse = await fetch(
                    `${config.VUE_APP_CMS_BASE_URL}/api/iso?limit=0&locale=${this.$i18n.locale ||
                        'nl'}`
                )

                const isoRules = await isoRulesResponse.json()
                const wcagRules = await wcagRulesResponse.json()
                this.errorMessages = [...isoRules.docs, ...wcagRules.docs]
            } catch (error) {
                console.error(error)
                /* TODO show this error somewhere in the frontend */
                this.error = error
            }
        },
        updateCategoryNames() {
            this.categoryNames = {
                structure: this.$t('results.structure'),
                format: this.$t('results.format'),
                font: this.$t('results.font'),
                metadata: this.$t('results.metadata'),
                accessibility: this.$t('results.accessibility'),
                'alt-text': this.$t('results.altText'),
                code: this.$t('results.code'),
                other: this.$t('results.other'),
            }
        },
        zoomIn() {
            this.scale = this.scale + 0.25
        },
        zoomOut() {
            this.scale = this.scale - 0.25
        },
        setBbox({ page, bboxIndex }) {
            this.page = page
            this.activeBboxIndex = bboxIndex
        },
        viewerPageChange(page) {
            this.page = page
            this.showPage = true
            clearTimeout(this.pageTimer)
            this.pageTimer = setTimeout(() => {
                this.showPage = false
            }, 4000)
        },
        viewerLoadSuccess({ numPages }) {
            this.pages = numPages
        },
        viewerBboxClick(props) {
            if (props) {
                this.activeBboxIndex = props.index
            }
        },
        viewerBboxesParsed(data) {
            // Build up array of rules with checks
            let newCriticalRules = []
            let newRules = []

            let checkIndex = -1
            this.ruleSummaries.forEach(rule => {
                const { specification, clause, testNumber, checks } = rule

                const errorClause = this.errorMessages?.find(
                    item => item.specification === specification && item.clause === clause
                )

                if (!errorClause) {
                    console.log(`No error clause found for ${specification} ${clause}`)
                    return
                }

                const errorInformation = errorClause.testNumbers?.find(
                    item => item.number.toString() === testNumber.toString()
                )

                if (!errorInformation) {
                    console.log(
                        `No error information found for ${specification} ${clause} ${testNumber}`
                    )
                    return
                }

                const { status, category, message, technical } = errorInformation

                // Don't show rules that have status ignore
                if (status === 'ignore') {
                    return
                }

                // Don't show warnings if the user has disabled them
                if (!this.showWarnings && status === 'warning') {
                    return
                }

                // Don't show rules without a message
                if (!message?.summary) {
                    console.log(`No message found for ${specification} ${clause} ${testNumber}`)
                    return
                }

                let ruleObject = {
                    id: `${specification}${clause}${testNumber}`,
                    checks: [],
                    fonts: this.getFontErrors(checks),
                }

                rule.checks.forEach(() => {
                    checkIndex++
                    if (data[checkIndex]) {
                        ruleObject.checks.push({
                            bboxIndex: checkIndex,
                            page: data[checkIndex],
                        })
                    }
                })

                ruleObject.summary = message?.summary
                ruleObject.description = message?.description
                ruleObject.technicalSummary = technical?.summary
                ruleObject.technicalDescription = technical?.description

                if (status) {
                    ruleObject.status = status

                    if (status === 'critical') {
                        newCriticalRules.push(ruleObject)
                        return
                    }

                    if (newRules[category]) {
                        newRules[category].push(ruleObject)
                    } else {
                        newRules[category] = [ruleObject]
                    }
                } else {
                    if (newRules.other) {
                        newRules.other.push(ruleObject)
                    } else {
                        newRules.other = [ruleObject]
                    }
                }
            })

            this.criticalRules = newCriticalRules
            this.rules = newRules
        },
        calculateScore(foundCritical, foundSerious, foundError) {
            var score =
                (100 -
                    (foundCritical * scoring['weight-critical'] +
                        foundSerious * scoring['weight-serious'] +
                        foundError * scoring['weight-error'])) /
                100
            score = score < 0 ? 0 : score
            var roundedScore = Number.parseFloat(score * 100).toFixed(0)
            return roundedScore
        },
        getFontErrors(checks) {
            var fonts = []
            checks
                .map(check => check.context)
                .forEach(context => {
                    var font = [...context.matchAll(/(?:usedGlyphs\[(.)]\()(.*?)(?=\))/g)]
                    if (font.length === 0) {
                        font = [...context.matchAll(/(?:font\[(.)]\()(.*?)(?=\))/g)]
                    }

                    for (const match of font) {
                        if (match[match.length - 1] && match[match.length - 1].includes('+')) {
                            var strippedFont = [...match[match.length - 1].matchAll(/(?:\+)(.*)/g)]
                            for (const match of strippedFont) {
                                match[match.length - 1] &&
                                    fonts.push(
                                        match[match.length - 1]
                                            .replace(/[0-9]/g, '')
                                            .replace(/\s*$/g, '')
                                    )
                            }
                        } else {
                            match[match.length - 1] && fonts.push(match[match.length - 1])
                        }
                    }
                    // create unique entries
                    fonts = [...new Set(fonts)]
                })
            return fonts
        },
    },
}
</script>

<style scoped>
.page-top,
.page-bottom {
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    pointer-events: none;
}

.page-top {
    top: 8px;
}
.page-zoom {
    margin-right: auto;
    margin-left: 20px;
    display: flex;
    background-color: white;
    border-radius: var(--radius-normal);
    box-shadow: var(--shadow-normal);
    overflow: hidden;
    pointer-events: auto;
}

.page-zoom button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.page-zoom button:not(:last-child) {
    border-right: 1px solid var(--color-grey-30);
}

.page-zoom button[disabled] {
    background: var(--color-grey-10);
    color: var(--color-icon-grey);
}

.page-zoom button:not([disabled]):hover {
    background: var(--color-hover);
}

.page-zoom button:not([disabled]):active {
    background: var(--color-active);
}

.page-bottom {
    bottom: 8px;
}

.page-status {
    margin: 0 auto;
    padding: 2px 5px 3px;
    background-color: #222;
    color: white;
    font-size: var(--font-size-tiny);
    font-weight: var(--font-weight-bold);
    border-radius: var(--radius-normal);
    box-shadow: var(--shadow-normal);
    pointer-events: auto;
}

.results {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 64px var(--size-small) !important;
    box-sizing: border-box;
}

.results-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.score {
    position: relative;
    margin: 0 auto 16px;
    display: flex;
    width: 100px;
}

.score-bg {
    fill: none;
    stroke: var(--color-grey-20);
    stroke-width: 2.6;
}

.score-circle {
    fill: none;
    stroke-width: 2.6;
}

.score-container {
    height: 100px;
    width: 100px;
}

.score-good .score-circle {
    stroke: #08c665;
}
.score-average .score-circle {
    stroke: #ffc400;
}
.score-bad .score-circle {
    stroke: #ff0000;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.score-percentage span {
    margin-left: 2px;
    font-size: var(--font-size-normal);
    color: var(--color-text-grey);
}

.score-percentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: var(--font-size-title-normal);
    font-weight: var(--font-weight-bold);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-message {
    text-align: center;
    font-size: var(--font-size-normal);
    color: black;
    margin-bottom: 24px;
}

.buttons {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.buttons *:not(:last-child) {
    margin-right: 12px;
}

.zoomButton:focus-visible {
    -webkit-box-shadow: inset 0px 0px 0px 5px var(--color-primary-active);
    -moz-box-shadow: inset 0px 0px 0px 5px var(--color-primary-active);
    box-shadow: inset 0px 0px 0px 5px var(--color-primary-active);
}

.checkbox-container {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
}

.show-warnings-checkbox-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding-bottom: 24px;
}

@media (min-width: 768px) {
    .show-warnings-checkbox-container {
        grid-template-columns: 1fr 1fr;
    }
}

.checkbox-text {
    font-size: var(--font-size-normal);
    padding-left: 10px;
}

input[type='checkbox'] {
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
}

.warning-explanation {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.warning-explanation__warning,
.warning-explanation__error {
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    gap: 8px;
}
</style>
